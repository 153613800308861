 .menu-container {

   padding-top: 90px;
 }

 .menu-header {
   position: fixed;
   top: -1px;
   width: 100%;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   background-color: #DB0000;
   height: 58px
 }

 .f-container {
   display: flex;
   flex-direction: row;
 }

 .product-card {
   border-width: 1px;
   border-color: #e0e0e0;
   border-style: solid;
   flex: 1;
   margin: 5px 0;
   display: flex;
   flex-direction: row;
   border-radius: 5px;
   min-height: 120px;

 }

 .div-img {
   width: 40%;
   padding: 3px;
 }

 .div-content {
   width: 60%;
 }

 .f-img {
   max-width: 100%;
   height: auto;
   min-height: 145px;
   border-top-left-radius: 5px;
   border-bottom-left-radius: 5px;

 }

 .f-img-0 {
   max-width: 100%;
   height: auto;

 }


 .f-price {
   margin: 0;
   position: absolute;
   bottom: 5px;
   font-weight: 500;
 }


 .menu-category-item {
   width: 130px;
   background-color: #e0e0e0;
   margin: 0 5px;
   padding: 10px;

   text-align: center;
 }

 .category-block {
   margin-top: 10px;
   padding: 10px;
   font-size: 18px;
   font-weight: 900;
   background-color: #e0e0e0;
   border-top: 1px solid #c0c0c0;
   border-bottom: 1px solid #c0c0c0;
 }

 .dropdown-toggle::after {
   display: none !important;
 }

 .div-row {
   display: flex;
   flex-direction: row;
   align-items: center;
 }

 .div-col-center {
   display: flex;
   flex-direction: column;
   align-items: center;
 }

 .div-row-space-between {
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
 }

 .button-days {
   margin: 0 5px;
   width: 80px;
 }

 .footer {
   position: fixed;
   bottom: 10px;
   width: 100%;
   display: flex;
   flex-direction: column;
   padding: 10px;
   height: 58px;
 }

 .f-close-button {
   position: absolute;
   top: 5px;
   right: 5px;
   z-index: 1000;
   background-color: black;
   border-radius: 50%;
   padding: 10px;
   display: flex;
   justify-content: center;
   align-items: center;
 }

 .text-small-grey {
   font-size: 12px;
   color: #999;
   margin-top: 5px;
   max-height: 55px;
   overflow: hidden;
 }


 .date-picker-custom {
   width: 130px;
 }

 .checkboxOne label {
   font-size: 1.1em;
 }